import React, { useState, useEffect } from "react";
import "./sidebar.css"
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import SportsIcon from '@mui/icons-material/Sports';
import SourceIcon from '@mui/icons-material/Source';
import QuizIcon from '@mui/icons-material/Quiz';
import TurnedInIcon from '@mui/icons-material/TurnedIn';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import InventoryIcon from '@mui/icons-material/Inventory';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import * as AuthService from "../../services/auth/auth.service";
import IUser from "../../interface/user.type";
import { Drawer } from "@mui/material";

const Sidebar: React.FC = () => {
    let navigate: NavigateFunction = useNavigate();

    const [showAdminBoard, setShowAdminBoard] = useState<boolean>(false);
    const [currentUser, setCurrentUser] = useState<IUser | undefined>(undefined);

    useEffect(() => {
        const user = AuthService.getCurrentUser();
        if (user) {
            setCurrentUser(JSON.parse(JSON.parse(user)));
            setShowAdminBoard(JSON.parse(JSON.parse(user)).roles[0] === "ROLE_ADMIN");
        }
    }, []);

    let location = useLocation();
    if (location.pathname === '/Error') {
        return null;
    }

    return (
        <>
            {currentUser ? (
                <Drawer
                    className="sidebar"
                    variant="permanent"
                    sx={{
                        width: 240,
                        flexShrink: 0,
                        [`& .MuiDrawer-paper`]: { top: "60px", backgroundColor: "rgba(239, 235, 234, 0.458)", width: 240, boxSizing: 'border-box' },
                    }}>
                    <div className="center">
                        <ul>
                            {/*<p className="title">MAIN</p>
                            <li onClick={() => { navigate("Dashboard"); window.scrollTo(0, 0); }}>
                                <DashboardIcon className="icon" />
                                <span>
                                    Dashboard
                                </span>
                </li>*/}
                            {
                                showAdminBoard ? (
                                    <>
                                        <p className="title">HUMAN MANAGEMENT</p>
                                        <li onClick={() => { navigate("Examinees"); window.scrollTo(0, 0); }}>
                                            <PeopleIcon className="icon" />
                                            <span>
                                                Examinees
                                            </span>
                                        </li>
                                        <li onClick={() => { navigate("Instructor"); window.scrollTo(0, 0); }}>
                                            <SportsIcon className="icon" />
                                            <span>
                                                Instructors
                                            </span>
                                        </li>
                                    </>
                                ) : (
                                    <div />
                                )

                            }

                            <p className="title">RESOURCE MANAGEMENT</p>
                            <li onClick={() => { navigate("Assessment"); window.scrollTo(0, 0); }}>
                                <QuizIcon className="icon" />
                                <span>
                                    Assessment Form
                                </span>
                            </li>
                            <li onClick={() => { navigate("/Lesson"); window.scrollTo(0, 0); }}>
                                <ImportContactsIcon className="icon" />
                                <span className="temp">
                                    Lessons
                                </span>
                            </li>
                            <li onClick={() => { navigate("Content"); window.scrollTo(0, 0); }}>
                                <SourceIcon className="icon" />
                                <span>
                                    Contents
                                </span>
                            </li>

                            <p className="title">SUBMISSION</p>
                            <li onClick={() => { navigate("AssessmentSubmittion"); window.scrollTo(0, 0); }}>
                                <TurnedInIcon className="icon" />
                                <span>
                                    Assessment Submittion
                                </span>
                            </li>
                            <li onClick={() => { navigate("/WeeklyTask"); window.scrollTo(0, 0); }}>
                                <TurnedInIcon className="icon" />
                                <span>
                                    Weekly Task Submittion
                                </span>
                            </li>

                            <p className="title">INTERACTION</p>
                            <li onClick={() => { navigate("/MessageBox"); window.scrollTo(0, 0); }}>
                                <InventoryIcon className="icon" />
                                <span>
                                    Message Box
                                </span>
                            </li>
                            <li onClick={() => { navigate("/SharingBoard"); window.scrollTo(0, 0); }}>
                                <NoteAltIcon className="icon" />
                                <span>
                                    Sharing broad
                                </span>
                            </li>
                            <li onClick={() => { navigate("/ChatBot"); window.scrollTo(0, 0); }}>
                                <SmartToyIcon className="icon" />
                                <span>
                                    ChatBot
                                </span>
                            </li>
                        </ul>
                    </div>
                </Drawer>
            ) : (
                <div />
            )
            }
        </>

    );
}

export default Sidebar