import React, { useState, useEffect } from "react";
import * as AuthService from "../../services/auth/auth.service";
import MenuIcon from '@mui/icons-material/Menu';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import IUser from "../../interface/user.type";
import { useLocation } from "react-router-dom";

type BoxProps = {
    Sidebar: boolean;
    controller: any;
};

const Header: React.FC<BoxProps> = ({ Sidebar, controller }) => {
    const [currentUser, setCurrentUser] = useState<IUser | undefined>(undefined);


    useEffect(() => {
        const user = AuthService.getCurrentUser();
        if (user) {
            setCurrentUser(JSON.parse(JSON.parse(user)));
        }
    }, []);

    let location = useLocation();
    if (location.pathname === '/Error') {
        controller(false)
        return null;
    }

    return (
        <>
            {currentUser ? (
                <Navbar fixed="top" bg="dark" expand="lg" variant="dark">
                    <Navbar.Brand style={{ cursor: "pointer" }}>
                        <span onClick={() => {
                            controller(!Sidebar);
                        }}>
                            <MenuIcon style={{ fontSize: "30px", alignItems: "center" }} />
                        </span>
                    </Navbar.Brand><Nav className="mr-auto">
                        <li className="nav-item">
                            <a href="/Assessment" className="nav-link">
                                iMBHQ
                            </a>
                        </li>
                    </Nav><Nav className="ml-auto">
                        <li className="nav-item">
                            <a href="/" className="nav-link" onClick={AuthService.logout}>
                                LogOut
                            </a>
                        </li>
                    </Nav>
                </Navbar>
            ) : (
                <Navbar fixed="top" bg="dark" expand="lg" variant="dark" />
            )}
        </>
    );
}

export default Header
