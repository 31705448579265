import axios from "axios";

const API_URL = process.env.REACT_APP_SYSTEM_MODE == "production" ? process.env.REACT_APP_PRODUCTION_BASE_URL : process.env.REACT_APP_SYSTEM_MODE == "internal" ? process.env.REACT_APP_INTERNAL_BASE_URL : process.env.REACT_APP_LOCAL_BASE_URL

export const login = (username: string, password: string) => {
    return axios.post(API_URL + "admins/signin", {
        username, password
    })
        .then(response => {
            if (response.data.accessToken) {
                localStorage.setItem("user", JSON.stringify(response.data));
            }

            return response.data;
        });

};

export const logout = () => {
    localStorage.removeItem("user");
};

export const getCurrentUser = () => {
    const userStr = localStorage.getItem("user");
    if (userStr)
        return JSON.stringify(userStr);

    return null;
};
