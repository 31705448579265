import React, { useState, lazy, Suspense, useEffect } from "react";
import { Routes, Route, Outlet, Navigate, useLocation, useNavigate, NavigateFunction } from "react-router-dom";
import Header from './components/navbar/Header';
import Sidebar from './components/sidebar/sidebar';
import "bootstrap/dist/css/bootstrap.min.css";
import ReactLoading from "react-loading";
import { Box } from "@mui/material";
import jwt_decode from "jwt-decode";
import { logout } from "./services/auth/auth.service"

//page
const Login = lazy(() => import("./screens/LoginScreen"))
//const Dashboard = lazy(() => import("./screens/Dashboard"))
const ExamineesList = lazy(() => import("./screens/ExamineesListScreen"))
const AddExaminee = lazy(() => import("./screens/AddExamineeScreen"))
const EditExaminee = lazy(() => import("./screens/EditExamineeScreen"))
const ExamineeProfile = lazy(() => import("./screens/ExamineeProfileScreen"))
const InstructorList = lazy(() => import("./screens/InstructorListScreen"))
const AddInstructor = lazy(() => import("./screens/AddInstructorScreen"))
const EditInstructor = lazy(() => import("./screens/EditInstructorScreen"))
const Assessment = lazy(() => import("./screens/AssessmentForm"))
const AssessmentSubmission = lazy(() => import("./screens/AssessmentSubmission"))
const Lesson = lazy(() => import("./screens/LessonScreen"))
const LessonDetail = lazy(() => import("./screens/LessonDetailScreen"))
const ContentsList = lazy(() => import("./screens/ContentsListScreen"))
const MessageBox = lazy(() => import("./screens/MessageBoxScreen"))
const WeeklyTask = lazy(() => import("./screens/WeeklyTaskScreen"))
const SharingBoard = lazy(() => import("./screens/SharingBoardScreen"))
const ChatBot = lazy(() => import("./screens/ChatBotScreen"))
const Error404 = lazy(() => import("./screens/Error404Screen"))
const Redirect = lazy(() => import("./screens/RedirectScreen"))

const App: React.FC = () => {
    const [showSidebar, setSidebar] = useState<boolean>(true);
    const location = useLocation();
    let navigate: NavigateFunction = useNavigate();

    useEffect(() => {
        var user = localStorage.getItem("user")
        if (!user && location.pathname != "/" && location.pathname != "/Redirect") {
            navigate("/Error");
        } else {
            if (user) {
                var token: string = JSON.parse(user).accessToken
                var decodedJWT: { foo: string, exp: number, iat: number } = jwt_decode(token)
                if (decodedJWT.exp * 1000 <= Date.now()) {
                    logout()
                    navigate("/Redirect")
                    window.location.reload()
                }
            }
        }
    }, [location.pathname])

    return (
        <Box sx={{ display: 'flex' }}>
            <Header Sidebar={showSidebar} controller={setSidebar} />

            {showSidebar ? <Sidebar /> : <div />}

            <div style={{ flex: 15, borderLeft: "0.5px solid rgb(0, 0, 0)", paddingBottom: "2%", minHeight: "100vh", height: "100%", paddingTop: "10vh" }}>
                <div className="container mt-3">
                    <Routes>
                        <Route path="/" element={<><Suspense fallback={<div style={{ position: "absolute", top: "50%", left: "50%" }}><ReactLoading type={"bars"} color="#000000" /></div>}><Outlet /></Suspense></>}>
                            <Route path="/">
                                <Route index element={<Login />} />
                                {/*<Route path="Dashboard" element={<Dashboard />} />*/}
                                <Route path="Assessment">
                                    <Route index element={<Assessment />} />
                                </Route>
                                <Route path="Examinees">
                                    <Route index element={<ExamineesList />} />
                                    <Route path="Profile/:id" element={<ExamineeProfile />} />
                                    <Route path="Edit/:id" element={<EditExaminee />} />
                                    <Route path="Add" element={<AddExaminee />} />
                                </Route>
                                <Route path="Instructor">
                                    <Route index element={<InstructorList />} />
                                    <Route path="Edit/:id" element={<EditInstructor />} />
                                    <Route path="Add" element={<AddInstructor />} />
                                </Route>
                                <Route path="AssessmentSubmittion">
                                    <Route index element={<AssessmentSubmission />} />
                                </Route>
                                <Route path="Lesson">
                                    <Route index element={<Lesson />} />
                                    <Route path="Detail/:id" element={<LessonDetail />} />
                                </Route>
                                <Route path="Content">
                                    <Route index element={<ContentsList />} />
                                </Route>
                                <Route path="MessageBox">
                                    <Route index element={<MessageBox />} />
                                </Route>
                                <Route path="WeeklyTask">
                                    <Route index element={<WeeklyTask />} />
                                </Route>

                                <Route path="SharingBoard">
                                    <Route index element={<SharingBoard />} />
                                </Route>
                                <Route path="ChatBot">
                                    <Route index element={<ChatBot />} />
                                </Route>

                                <Route path="Redirect" element={<Redirect />} />
                                <Route path="*" element={<Navigate to="/Error" />} />
                                <Route path="Error" element={<Error404 />} />
                            </Route>
                        </Route>
                    </Routes>
                </div>

            </div>

        </Box>
    );
}


export default App;